<template>
  <v-container>
    <div class="d-flex flex-wrap align-center justify-space-between">
      <h4 class="my-5">احصائيات الطلبات</h4>
      <div class="d-flex" v-if="data_loaded">
        <v-select
          v-model="filter_val"
          @input="filter($event)"
          item-text="text"
          item-value="type"
          solo
          label="نوع البحث"
          :items="filter_select"
        ></v-select>
        <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value.sync="date"
          persistent
          width="290px"
          v-if="range_filter"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              label="Picker in dialog"
              prepend-inner-icon="mdi-calendar"
              readonly
              solo
              v-bind="attrs"
              v-on="on"
              class="mr-3"
            ></v-text-field>
          </template>
          <v-date-picker range v-model="date" scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modal = false"> الغاء </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.dialog.save(date), filter('range')"
            >
              تم
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </div>
    </div>

    <v-row v-if="!data_loaded">
      <v-col v-for="a in 5" :key="a">
        <v-skeleton-loader elevation="1" type="article"></v-skeleton-loader>
      </v-col>
      <v-col cols="12">
        <v-skeleton-loader elevation="1" type="article"></v-skeleton-loader>
      </v-col>
    </v-row>

    <v-row v-if="data_loaded">
      <v-col class="stats" v-for="(stat, i) in stats" :key="i">
        <v-card :color="stat.color">
          <v-card-title
            ><h4 class="white--text">{{ stat.title }}</h4></v-card-title
          >
          <v-card-text class="white--text">
            <h2 class="mb-2">
              {{
                stats_values != null
                  ? stats_values[stat.type].total_orders
                  : null
              }}
            </h2>
            <h2>
              {{ stats_values != null ? stats_values[stat.type].total : null }}
              ₪
            </h2>
          </v-card-text>
        </v-card>
      </v-col>
      <!--/ col -->
    </v-row>

    <v-row v-if="data_loaded">
      <v-col cols="6">
        <v-card color="blue">
          <v-card-title
            ><h4 class="white--text">اجمالي الطلبات</h4></v-card-title
          >
          <v-card-text class="white--text">
            <h2>{{ stats_values != null ? stats_values.total_orders : 0 }}</h2>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="6">
        <v-card color="#525252">
          <v-card-title
            ><h4 class="white--text">اجمالي المبلغ</h4></v-card-title
          >
          <v-card-text class="white--text">
            <h2>{{ stats_values != null ? stats_values["total"] : 0 }} ₪</h2>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "OrdersStats",
  data() {
    return {
      filter_data: null,
      modal: false,
      date: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      ],
      range_filter: false,
      data_loaded: false,
      filter_val: {
        type: "today",
        text: "اليوم",
      },
      filter_select: [
        {
          type: "today",
          text: "اليوم",
        },
        {
          type: "week",
          text: "الأسبوع الحالي",
        },
        {
          type: "month",
          text: "الشهر الحالي",
        },
        {
          type: "year",
          text: "السنة الحالية",
        },
        {
          type: "range",
          text: "تاريخ اخر",
        },
      ],
      stats: [
        {
          title: "جديد",
          color: "#e58200",
          type: "new",
        },
        {
          title: "قيد التحضير",
          color: "#644ae1",
          type: "preparation",
        },
        {
          title: "تم التجهيز",
          color: "#202020",
          type: "prepared",
        },
        {
          title: "تم التجهيز",
          color: "success",
          type: "done",
        },
        {
          title: "ملغي",
          color: "error",
          type: "canceled",
        },
      ],
    };
  },
  computed: {
    stats_values() {
      return this.filter_data ? this.filter_data : null;
    },
    filter_url() {
      return `orders_filter/${this.date[0]} ${
        this.date.length > 1 ? "/" + this.date[1] : ""
      }`;
    },
  },
  methods: {
    filter(ev) {
      this.data_loaded = false;
      this.range_filter = ev == "range";
      this.$store
        .dispatch("public__request", {
          config: {
            url: this.filter_url,
            method: "post",
          },
          data: {
            type: this.filter_val,
          },
        })
        .then((res) => {

          this.filter_data = Object.assign({}, res.data.data);
          this.data_loaded = true;
          this.modal = false;
        })
    },
  },
  created() {
    this.filter("today");
  },
};
</script>

<style lang="scss" scoped>
.stats {
  @media (max-width: 767px) {
    flex-basis: 50%;
  }
}
</style>
